import React, { ReactElement } from "react";
import * as styles from "./ServicesPage.module.scss";
import { graphql } from "gatsby";
import TextBlock from "./TextBlock";
import Contact from "../../components/Shared/Contact";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

interface IServiceProps {
    data: GatsbyTypes.ServiceQuery;
}
const ServicesPageTemplate = ({ data }: IServiceProps): ReactElement => {
    const { strapiServicePage, allStrapiServicePage } = data;

    return (
        <main className={styles.wrapper}>
            <div className="mainBox">
                <TextBlock otherServices={allStrapiServicePage?.nodes} {...strapiServicePage?.ourServices} />
            </div>
            <Contact theme="dark" />
        </main>
    );
};

export default ServicesPageTemplate;

export const query = graphql`
    query Service($id: String!) {
        strapiServicePage(id: { eq: $id }) {
            id
            themes {
                footer
                navbar
            }
            title
            nav
            ourServices {
                image
                subHeading
                heading
                paragraphHTML
            }
        }
        allStrapiServicePage {
            nodes {
                id
                nav
                title
                ourServices {
                    heading
                }
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id='site-title'>{seo.title}</title>
            <meta id='desc' name="description" content={seo.description} />
            <meta id='tw-card' name="twitter:card" content={seo.twitter.card } />
            <meta id='tw-site' name="twitter:site" content={seo.twitter.site} />
            <meta id='tw-creator' name="twitter:creator" content={seo.twitter.creator } />
            <meta id='tw-title' name="twitter:title" content={seo.twitter.title } />
            <meta id='tw-desc' name="twitter:description" content={seo.twitter.description } />
            <meta id='tw-img' name="twitter:image" content={seo.twitter.image.url } />
            <meta id='fb-type' property="og:type" content={seo.facebook.type } />
            <meta id='fb-title' property="og:title" content={seo.facebook.title } />
            <meta id='fb-desc' property="og:description" content={seo.facebook.description } />
            <meta id='fb-url' property="og:url" content={seo.facebook.url } />
            <meta id='fb-img' property="og:image" content={seo.facebook.image.url } />
        </SiteMetadataHead>
    );
}