import React from "react";
import * as styles from "./Menu.module.scss";
import { Link } from "gatsby";
import slugify from "../../../helpers";

const Menu = ({ otherServices }: any) => {
    const sortFunction = (a: { [x: string]: number }, b: { [x: string]: number }, key: string) => (a[key] > b[key] ? 1 : -1 || 0);
    if (!otherServices) otherServices = [];
    const servicesOrd = otherServices.sort((a: { [x: string]: number }, b: { [x: string]: number }) => sortFunction(a, b, "title"));

    return (
        <section className={styles.wrapper}>
            <h1 className={styles.navTitle}>Other Services</h1>
            <ul>
                {otherServices &&
                    servicesOrd.map((info: any, i: number) => (
                        <li key={i}>
                            <Link to={`/services/${slugify(info.title)}`} className={styles.link} activeClassName={styles.isActive}>
                                {info.ourServices.heading}
                            </Link>
                        </li>
                    ))}
            </ul>
        </section>
    );
};

export default Menu;
