import React from "react";
import Menu from "../Menu";
import * as styles from "./TextBlock.module.scss";

interface ITextBlock {
    heading?: string | null;
    subHeading?: string | null;
    paragraphHTML?: string | null;
    image?: any;
    otherServices?: any;
}
const TextBlock = (props: ITextBlock) => {
    const { heading, subHeading, paragraphHTML, image, otherServices } = props;
    return (
        <section className={styles.wrapper}>
            <div>
                <h1 className={styles.servicesTitle}>{heading}</h1>

                <div className={styles.imgMobile} dangerouslySetInnerHTML={{ __html: image || "" }} />

                <p className={styles.subHeading}>{subHeading}</p>

                {paragraphHTML && <div className={styles.servicesParagraph} dangerouslySetInnerHTML={{ __html: paragraphHTML || "" }} />}

                <Menu otherServices={otherServices} />
            </div>

            <div className={styles.imgDesktop} dangerouslySetInnerHTML={{ __html: image || "" }} />
        </section>
    );
};

export default TextBlock;
